import { useState } from "react";
import { NavLink } from "react-router-dom"
import pharmpic from "../static/PharmPic.JPG"

export default function AboutMe() {

  let [activeTab, setActiveTab] = useState(null)

  const activeStyles = "active text-black text-2xl select-none bg-white border-black border-solid border-2 p-2";
  const styles = "text-slate-600 text-2xl select-none p-2";

  function ActiveText({ active }) {

    //todo maybe add photos?

    switch (active) {
      case "1": {
        return (
          <>
            <div className="m-5 text-center">
              <h2 className="text-4xl m-5">Software Developer</h2>

              <div className="flex">

                <img className="m-10 h-auto w-72 border-black border-4 border-solid" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRvWkDsG6sV02VR_N8jp2XtMhzFxEIkB_ao4scvA0LTGA&s" alt="upright logo"></img>

                <p className="text-2xl p-10 bg-white border-black border-2 shadow-lg">
                  After I had graduated Pharmacy school, I became more interested in how a Pharmacist could apply their background in the digital space. I had reached out to Pharmacists on linked in with varying roles and titles to get some insight. I decided on getting more expereince with software developemnt by enrolling in a Sofware Development Bootcmamp. I attended a bootcamp through Old Dominion University who partnered with Upright Education so I could learn the basics of coding. Throughout the bootcamp I had learned HTML, CSS, Javascript, MERN Stack. My final Capstone Project involved designing a Coaching Website for our sponsor that applied all I learned on top of integrating 3rd party APIs that our sponsor wanted.
                </p>
              </div>

            </div>
          </>
        )
      }
      case "2": {
        return (
          <>
            <div className="m-5 text-center">
              <h2 className="text-4xl m-5">HealthCare</h2>

              <div className="flex">
                <img className="m-10 h-auto w-72 border-black border-4 border-solid" src={pharmpic} alt="Pharmacy Pic"></img>

                <p className="text-2xl p-10 bg-white border-black border-2 shadow-lg">
                  I graduated Old Dominion University back in 2018 with a B.S. in Chemistry and Biochemistry. I wanted to become a pharmacist so I atttended Virginia Commonwealth University and graduated with my PharmD in 2023. Throughout my time in pharmacy I learned a lot about healthcare and the clinical role that pharmacists had to help patients. During my last year in clinical rotations, I had followed a Pharmacist who focused on Informatics. I saw an entirely different world that Pharmacist could apply their knowledge to, so I decided to shift my career goals to somewhere in the Digital Healthcare sector.
                </p>
              </div>

            </div>
          </>
        )
      }
      default: {
        return (
          <>
            <div className="text-center text-4xl">
              Who is Jason Gomez.....?
            </div>
          </>
        )
      }
    }

  }

  return (
    <>
      <div className="p-20 bg-[#D40427] h-[50em]">

        {/* //todo ADJUST so all boxes are same when switching */}
        <div className="text-center">
          <h1 className="text-4xl">Curious about my journey?</h1>
        </div>

        {/* //todo have like an services page thingy */}
        {/* navbar */}
        <div className="flex flex-row gap-20 justify-center m-5">
          <NavLink
            className={activeTab === "1" ? activeStyles : styles}
            onClick={() => setActiveTab("1")}>
            Software Development
          </NavLink>
          <NavLink
            className={activeTab === "2" ? activeStyles : styles}
            onClick={() => setActiveTab("2")}>
            Healthcare</NavLink>
        </div>

        <ActiveText active={activeTab} />

      </div>
    </>
  )
}