import CoachWeb from "../static/CoachImg.png"
import Reactchat from "../static/Reactchat.png"
import Zork from "../static/ZorkIcon.jpg"

export default function Projects() {

  let repoInfo = [
    // {
    //   name: "",
    //   repo: "",
    //   description: "",
    //   card: "",
    //   img: "",
    // TODO once i figure out deployments
    // website: ""
    // },
    {
      id: 3,
      name: "Coaching Website - 2024",
      repo: "jagome05/Coaching-Website",
      link: "https://github.com/jagome05/Coaching-Website",
      description: "This was my Capstone project for my software development bootcamp, where I was tasked with creating a coaching website for our sponsor who needed it for their business. The MERN stack was utilized for our frontend and backend framework. I was able to practice a lot of Github collaboration with this project, as well as use a lot of 3rd party APIs at the request of our sponsor for their business!",
      card: ["Github Collaboration", "3rd Party Web APIs"],
      img: CoachWeb,
    },
    {
      id: 2,
      name: "ReactChat - 2024",
      repo: "jagome05/ReactChat",
      link: "https://github.com/jagome05/ReactChat",
      description: "I created a chat application using the MERN Stack! It was a project that helped me establish using MongoDB and database storage. I had a lot of practice utilizing CRUD, so you can do things like store a user account, create rooms, and update and delete messages!",
      card: ["MERN Stack", "Database and Schemas", "CRUD Operations"],
      img: Reactchat,
    },
    {
      id: 1,
      name: "Zork Like: MouseTrap - 2024",
      repo: "jagome05/MouseTrap-Zork-Like",
      link: "https://github.com/jagome05/MouseTrap-Zork-Like",
      description: "This game helped me understand Javascript and practice a lot of the fundamentals. It is based off of the orginal Zork game! It is a text adventure game where you type commands to go through the story. It was one of my first projects, and it has a very basic story!",
      card: ["JavaScript", "Bash", "Vscode"],
      // todo update zork image
      img: Zork,
    },
  ]

  function Card({ info }) {

    let cardInfo = info.map(each =>
      <ul className="text-lg" key={each.index}>{each}</ul>
    )

    return (
      <>
        {/* //todo edit z-index */}
        {/* <div className="bg-blue-300 border-black border-solid p-5 border-2 absolute flex flex-col z-10 group-hover:bg-green-300 group-hover:-translate-x-20"> */}
        <div className="bg-[#2B2D42] text-white p-6 border-white border-solid border-2 shadow-2xl rounded-2xl -bottom-36 left-0 right-0 m-auto w-4/5 absolute opacity-0 transition-[opacity_1s_1s] 
                        group-hover:animate-[showData_1s_forwards] group-hover:opacity-100 group-hover:[transition:opacity_.3s]
                        [&:not(:hover)]:animate-[removeData_2s_forwards]">
          <h1 className="font-bold text-2xl">Highlighted Skills:</h1>
          {cardInfo}
        </div>
      </>
    )
  }

  return (
    <>
      <div className="m-20 content-center text-black">

        {repoInfo.map(project =>
          <>
            <div key={project.id} className="bg-white rounded-2xl relative my-10 p-5 flex flex-row">

              <div className="basis-1/2 w-fit relative rounded-2xl overflow-hidden m-5 shadow-2xl group
                                  hover:animate-[removeOverflow_2s_forwards]
                                  [&:not(:hover)]:animate-[showOverflow_2s_forwards]">
                <img alt={`${project.img}`} src={project.img}></img>
                <Card info={project.card} />
              </div>

              <div className="basis-1/2 flex flex-col h-auto gap-10">

                <div className="bg-white relative z-20 w-full h-fit p-5 rounded-2xl text-5xl">
                  <h1>{project.name}</h1>
                </div>

                <div className="bg-white relative z-20 w-full h-fit p-5 rounded-2xl text-xl">
                  <h2>{project.description}</h2>
                </div>

                <a href={project.link} target="_blank" rel="noreferrer">
                  <div class="repo-card" data-repo={project.repo}></div>
                </a>
              </div>
            </div>
          </>
        )}
      </div>
    </>
  )
}

// ? References
//https://www.youtube.com/watch?v=Q8BamnhOmWc&list=PL07efmqYWHZ9Acr4QPiecaGKsekcL2zvc&index=1
