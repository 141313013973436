import React from "react";
import portrait from "../static/Portrait.JPG"
import AboutMe from "./AboutMe";
import Projects from "../Components/Projects";
import Resume from "../../src/static/Jason_Gomez_Resume.pdf"

export default function LandingPage() {

  const onButtonClick = () => {
    const pdfUrl = Resume;
    const link = document.createElement("a");
    link.href = pdfUrl;
    link.download = Resume; // specify the filename
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
};

  return (
    <>
      <div className="p-5 bg-[#D40427]">

        <div className="p-10 flex flex-row items-center justify-center gap-20 ">

          {/* //todo maybe add onClick to change style? */}
          <div className="basis-1/2 flex flex-col gap-10 p-5 relative">

            {/*//* this is the weird whitebox */}
            <div className="absolute h-96 w-4/5 z-10 bg-white -translate-x-1/4 -translate-y-1/3 skew-y-3 border-black border-2 shadow-inner"></div>

            <div className="w-10/12 flex flex-col gap-4 p-2 z-20 font-semibold">
              <h2 className="text-3xl text-[#D40427]">Hello! My name is</h2>
              <h1 className="text-6xl font-bold text-[#07080D]"><span className="text-7xl">J</span>ason <span className="text-7xl">G</span>omez</h1>
              <h2 className="text-3xl text-[#D40427]">Welcome to my Portfolio Page!</h2>
            </div>

            {/* //todo edit buttons */}
            {/* <button className="text-lg font-semibold border-black border-2 p-2 mx-2 w-1/4 rounded-md bg-white text-black">Contact Me</button> */}
            {/* <button className="text-lg font-semibold border-black border-2 p-2 mx-2 w-1/4 rounded-md bg-white text-black">Download my Resume!</button> */}

            <div className="flex flex-row justify-center gap-10 m-5 z-20 -translate-x-20 translate-y-10">

              <a href="https://www.linkedin.com/in/jason-gomez-681883164/" target="_blank" rel="noreferrer">
                <img className="h-24" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/linkedin/linkedin-plain.svg" alt="linkedIn"/>
              </a>

              <a href="https://github.com/jagome05" target="_blank" rel="noreferrer">
                <img className="h-24" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/github/github-original.svg" alt="Github"/>
              </a>

            </div>

          </div>

          <div className="basis-1/2 p-5 flex flex-col items-center relative ">

            {/* div frame for img */}
            <div className="overflow-hidden relative border-black border-2 w-1/2 rounded-3xl z-20 translate-y-16">
              <img className="w-full" src={portrait} alt="Portrait"></img>
            </div>

            {/* //todo possibly move more to right? */}
            <div className="relative z-20 bottom-28 right-8 bg-white border-black border-2 w-2/3 p-2 shadow-2xl translate-y-20">
              <h1 className="font-semibold text-lg">
                Hello! My name is Jason Gomez and I am a full-stack developer with a passion for how technology can be used to help improve outcomes in the healthcare field. I have a healthcare background and would love to contribute my adaptable mindset to a team-setting. I am driven and enthusiastic for continuous learning inside and outside of the workplace. I apply an analytical and systematic approach to problem solving and solutions.
              </h1>
            </div>
            {/* //todo style buttons somewhere else? */}
            <div className="z-10 absolute h-96 w-10/12 bg-transparent -translate-x-12 translate-y-1/2 -skew-y-6 border-black border-4 flex flex-col py-2 gap-2">
              {/* <button className="text-lg font-semibold border-black border-2 p-2 mx-2 w-1/4 rounded-md bg-white text-black">Contact Me!</button> */}
              <button onClick={onButtonClick} className="text-lg font-semibold border-black border-2 p-2 mx-2 w-1/4 rounded-md bg-white text-black">Download my Resume!</button>
            </div>
            {/* //todo move to center? */}
            <div className="absolute h-96 w-3/4 bg-white z-10 translate-x-1/4 skew-y-6 border-black border-2"></div>
          </div>
        </div>
      </div>

      <div className="bg-white text-center p-5">

        <div className="text-center py-5">
          <h1 className="text-3xl">Tech Stack & Tools</h1>
        </div>

        <div className="p-5 grid grid-flow-col justify-evenly">

          <img className="h-20" alt="html" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/html5/html5-original.svg" />
          <img className="h-20" alt="css" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/css3/css3-original.svg" />
          <img className="h-20" alt="tailwindcss" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/tailwindcss/tailwindcss-original.svg" />
          <img className="h-20" alt="javascript" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/javascript/javascript-original.svg" />
          <img className="h-20" alt="nodejs" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/nodejs/nodejs-original.svg" />
          <img className="h-20" alt="mongoDB" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/mongodb/mongodb-original.svg" />
          <img className="h-20" alt="express" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/express/express-original.svg" />
          <img className="h-20" alt="React" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/react/react-original.svg" />
          <img className="h-20" alt="Github" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/github/github-original.svg" />
        </div>

        <hr className="border-slate-700 border-solid self-center m-10"></hr>

        <div className="m-15 text-center h-96">
          <h2 className="text-3xl">Currently Learning:</h2>

          <div className="p-5 my-5 flex flex-row justify-center gap-20 text-black">

            <div className="basis-1/2 flex justify-evenly ">
              <img className="h-40" alt="python" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/python/python-original.svg" />
              <img className="h-40" alt="postgresql" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/postgresql/postgresql-original.svg" />
              <img className="h-40" alt="django" src="https://cdn.jsdelivr.net/gh/devicons/devicon@latest/icons/django/django-plain.svg" />
            </div>

            {/* <div className="basis-1/2 bg-gray-300 flex flex-col justify-evenly">

              <div className="basis-1/4 bg-blue-300">
              <h1 className="text-2xl">Project: Weather App</h1>
              </div>

              <div className="basis-3/4 bg-green-300">
              <h1 className="text-2xl">Description</h1>
              <li>Data Structure and Schemas</li>
              <li>Python for...</li>
              <li>Django for..</li>
            </div>

            </div> */}

          </div>
        </div>

      </div>

      <div className="bg-[#2B2D42] m-5">

        <div className="text-center py-5">
          <h1 className="text-4xl text-white">Projects</h1>
        </div>

        <div className="text-center">
          <h2 className="text-white text-3xl m-10">These are my past projects!</h2>

          <Projects />

        </div>
      </div>
      <AboutMe />
    </>
  )
}