import { Outlet } from "react-router-dom";
import React from "react";
import Header from "../Components/Header";
import Footer from "../Components/Footer";

//TODO
//todo choose text font

export default function AppLayout() {

  return (
    <div className="bg-[#2B2D42] font-mono">
      {/* //todo move header to weird shape? */}
      {/* <Header /> */}
      <main>
        <Outlet/>
      </main>
      {/* <Footer /> */}
    </div>
  );
}